import React, { createContext, useState, ReactNode, useContext } from 'react';

interface AskuserContextType {
  showAskuserModal: boolean;
  selectedChildId: number | null;
  selectedChildName: string;
  taskToAsk: number | undefined;
  userRole: 'student' | 'user';
  openAskuserModal: (childId: number, childName: string, taskId?: number) => void;
  closeAskuserModal: () => void;
}

const AskuserContext = createContext<AskuserContextType | undefined>(undefined);

interface AskuserProviderProps {
  children: ReactNode;
}

export const AskuserProvider: React.FC<AskuserProviderProps> = ({ children }) => {
  const [showAskuserModal, setshowAskuserModal] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState<number | null>(null);
  const [selectedChildName, setSelectedChildName] = useState<string>('');
  const [taskToAsk, setTaskToAsk] = useState<number | undefined>(undefined);
  const [userRole, setUserRole] = useState<'student' | 'user'>('student');

  const openAskuserModal = (childId: number, childName: string, taskId?: number) => {
  //  console.log('trying to open');
    setshowAskuserModal(true);
    setSelectedChildId(childId);
    setSelectedChildName(childName);
    setTaskToAsk(taskId);
  };

  const closeAskuserModal = () => {
    setshowAskuserModal(false);
  };

  return (
    <AskuserContext.Provider
      value={{
        showAskuserModal,
        selectedChildId,
        selectedChildName,
        taskToAsk,
        userRole,
        openAskuserModal,
        closeAskuserModal,
      }}
    >
      {children}
    </AskuserContext.Provider>
  );
};

export const useAskuser = (): AskuserContextType => {
  const context = useContext(AskuserContext);
  if (!context) {
    throw new Error('useAskuser must be used within an AskuserProvider');
  }
  return context;
};
