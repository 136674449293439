import React, { useContext, useState, useEffect } from "react";
import {
  CircularProgress,
  Button,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { CourseContext } from "../context/CourseContext";
import axios from "axios";
import useNotification from "../context/useNotification";
import { AuthContext } from "../context/AuthContext";
import { motion } from "framer-motion";
import FavoriteIcon from "@mui/icons-material/Favorite"; // أيقونة القلب
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"; // أيقونة القلب الفارغ
import { grey } from "@mui/material/colors"; // لاستخدام اللون الرمادي
import DiamondIcon from "@mui/icons-material/Diamond";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

interface PerformanceData {
  id: number;
  name: string;
  totalDegrees: number;
  rank: number;
  hearts: number;
  medal: string;
}

const medalIcons: { [key: string]: React.ReactNode } = {
  ماسية: <DiamondIcon style={{ color: "#b9f2ff" }} />,
  بلاتينية: <DiamondIcon style={{ color: "#e5e4e2" }} />,
  ذهبية: <MilitaryTechIcon style={{ color: "#ffd700" }} />,
  فضية: <MilitaryTechIcon style={{ color: "#c0c0c0" }} />,
  برونزية: <MilitaryTechIcon style={{ color: "#cd7f32" }} />,
  بيضاء: <EmojiEventsIcon style={{ color: "#cccccc" }} />,
};

const getMedalLevel = (hearts: number) => {
  if (hearts >= 1000) return "ماسية";
  if (hearts >= 750) return "بلاتينية";
  if (hearts >= 500) return "ذهبية";
  if (hearts >= 350) return "فضية";
  if (hearts >= 150) return "برونزية";
  if (hearts >= 1) return "بيضاء";
  return "بدون";
};

const getNextMedalInfo = (hearts: number) => {
  if (hearts < 150)
    return { nextMedal: "برونزية", requiredHearts: 150, currentLevelHearts: 0 };
  if (hearts < 350)
    return { nextMedal: "فضية", requiredHearts: 350, currentLevelHearts: 150 };
  if (hearts < 500)
    return { nextMedal: "ذهبية", requiredHearts: 500, currentLevelHearts: 350 };
  if (hearts < 750)
    return {
      nextMedal: "بلاتينية",
      requiredHearts: 750,
      currentLevelHearts: 500,
    };
  if (hearts < 1000)
    return {
      nextMedal: "ماسية",
      requiredHearts: 1000,
      currentLevelHearts: 750,
    };
  return {
    nextMedal: "أعلى ميدالية",
    requiredHearts: 1000,
    currentLevelHearts: 1000,
  };
};

const getProgressBarColor = (nextMedal: string) => {
  switch (nextMedal) {
    case "برونزية":
      return "#cd7f32";
    case "فضية":
      return "#c0c0c0";
    case "ذهبية":
      return "#ffd700";
    case "بلاتينية":
      return "#e5e4e2";
    case "ماسية":
      return "#b9f2ff";
    default:
      return "#ccc";
  }
};

const CoursePerformance: React.FC = () => {
  const courseContext = useContext(CourseContext);
  const triggerToast = useNotification();
  const auth = useContext(AuthContext);

  const [performanceData, setPerformanceData] = useState<PerformanceData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCoursePerformance = async () => {
    if (!auth || !auth.user) {
      triggerToast("برجاء تسجيل الدخول", "error");
      return;
    }

    if (!courseContext) {
      triggerToast("لا يوجد سياق دورة", "error");
      return;
    }

    const { activeCourse } = courseContext;

    if (!activeCourse) {
      triggerToast("لا يوجد دورة نشطة مختارة", "error");
      return;
    }

    setLoading(true);
    try {
      triggerToast("جارٍ تحديث بيانات الأداء...", "info");

      // جلب بيانات الأداء من نقطة النهاية الجديدة مع تمرير معرف الدورة
      const response = await axios.get(
        `https://codeoceantech.pythonanywhere.com/api/courses/${activeCourse.id}/course-performance/`,
        {
          headers: { Authorization: `Bearer ${auth?.user?.token.access}` },
        }
      );

      // الاستجابة المتوقعة تحتوي على بيانات الدورة والأطفال
      const courseData = response.data;
      let childrenData = courseData.children.map((child: any) => ({
        id: child.id,
        name: child.name,
        totalDegrees: child.total_degree,
      }));

      // ترتيب الطلاب حسب مجموع الدرجات نزولاً
      childrenData.sort((a: any, b: any) => b.totalDegrees - a.totalDegrees);

      // إضافة الترتيب، القلوب، والميدالية لكل طالب
      childrenData = childrenData.map((child: any, index: number) => {
        const hearts = child.totalDegrees; // بافتراض أن كل درجة هي قلب
        const medal = getMedalLevel(hearts);
        return {
          ...child,
          rank: index + 1,
          hearts,
          medal,
        };
      });

      setPerformanceData(childrenData);
    } catch (error) {
      console.error("Error fetching course performance data:", error);
      triggerToast("حدث خطأ أثناء تحديث البيانات", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoursePerformance();
  }, [auth]);

  if (!courseContext) {
    return (
      <div role="alert">
        خطأ: CourseContext غير معرّف. يرجى التأكد من أن المزوّد يلتف حول هذا
        المكوّن.
      </div>
    );
  }

  const { activeCourse } = courseContext;

  if (!activeCourse) {
    return (
      <div role="alert" className="text-gray-700">
        لا يوجد دورة نشطة مختارة. يرجى اختيار دورة لعرض الأداء.
      </div>
    );
  }

  return (
    <div className="p-4 rounded-md">
      <h2 className="text-center text-xl font-bold text-gray-800 mb-4">
        أداء الدورة: {activeCourse.title}
      </h2>
      {loading ? (
        <div className="flex justify-center">
          <CircularProgress size={50} />
        </div>
      ) : performanceData.length === 0 ? (
        <div className="text-center">
          <p>لا تتوفر بيانات أداء حاليًا.</p>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchCoursePerformance}
          >
            تنشيط البيانات
          </Button>
        </div>
      ) : (
        <div className="overflow-y-auto max-h-80" dir="rtl">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="px-5 py-3 border-b-2 border-gray-300 text-right">
                  الترتيب
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-300 text-right">
                  الاسم
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-300 text-right">
                  القلوب
                </th>
                {/* <th className="px-5 py-3 border-b-2 border-gray-300 text-right">
                  الميدالية القادمة
                </th> */}
                <th className="px-5 py-3 border-b-2 border-gray-300 text-right">
                  التقدم في الدورة{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {performanceData.map((child) => {
                const { nextMedal, requiredHearts, currentLevelHearts } =
                  getNextMedalInfo(child.hearts);
                const progress =
                  ((child.hearts - currentLevelHearts) /
                    (requiredHearts - currentLevelHearts)) *
                  100;

                return (
                  <motion.tr
                    key={child.id}
                    className={`border-b border-gray-200 hover:bg-gray-100 ${
                      child.rank <= 3 ? "bg-yellow-100" : ""
                    }`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <td className="px-5 py-5 text-sm text-gray-900 flex items-center justify-end">
                      {child.rank}
                      {child.rank === 1 && (
                        <span className="mr-2 text-yellow-500 text-xl">🥇</span>
                      )}
                      {child.rank === 2 && (
                        <span className="mr-2 text-gray-500 text-xl">🥈</span>
                      )}
                      {child.rank === 3 && (
                        <span className="mr-2 text-orange-500 text-xl">🥉</span>
                      )}
                    </td>
                    <td className="px-5 py-5 text-sm text-gray-900">
                      {child.name}
                    </td>
                    <td className="px-5 py-5 text-sm text-gray-900 flex items-center justify-end">
                      {child.hearts > 0 ? (
                        <FavoriteIcon style={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderIcon style={{ color: grey[500] }} />
                      )}
                      <span className="mr-2">{child.hearts}</span>
                    </td>

                    <td className="px-5 py-5 text-sm text-gray-900">
                      <Tooltip
                        title={`الميدالية التالية: ${nextMedal} (${child.hearts}/${requiredHearts} قلب)`}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={progress > 100 ? 100 : progress}
                          sx={{
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#f0f0f0",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: getProgressBarColor(nextMedal),
                            },
                          }}
                        />
                      </Tooltip>
                    </td>
                    {/* <td>
                    <Tooltip title={`الميدالية القادمة: ${nextMedal}`}>
                        <span className="flex items-center">
                          {medalIcons[nextMedal] || nextMedal}
                          <span className="mr-2">{nextMedal}</span>
                        </span>
                      </Tooltip>
                    </td> */}
                  </motion.tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CoursePerformance;
