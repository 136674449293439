import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import {
  Login,
  CheckCircle,
  NotificationsActive,
} from "@mui/icons-material";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "../../components/Common/firebaseConfig";
import useNotification from "../../context/useNotification";
import { useLocalStorage } from "../../context/LocalStorageContext";

const NotificationSettings: React.FC = () => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [notificationsEnabled, setNotificationsEnabled] =
    useState<boolean>(false);
  const [googleLoginEnabled, setGoogleLoginEnabled] =
    useState<boolean>(false);
  const { value: user, setValue: setUser } = useLocalStorage<{
    user_info: {
      user_id: string;
      username: string;
      email: string;
      phone_number: string;
    };
  }>();
  const triggerToast = useNotification();

  // وظيفة للحصول على معلومات المتصفح
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = "متصفح غير معروف";
    let version = "إصدار غير معروف";

    if (userAgent.includes("Chrome")) {
      browserName = "جوجل كروم";
      version = userAgent.split("Chrome/")[1].split(" ")[0];
    } else if (userAgent.includes("Firefox")) {
      browserName = "موزيلا فايرفوكس";
      version = userAgent.split("Firefox/")[1];
    } else if (userAgent.includes("Safari")) {
      browserName = "سفاري";
      version = userAgent.split("Version/")[1].split(" ")[0];
    }

    return { browserName, version };
  };

  // وظيفة للتحقق مما إذا كان الجهاز iOS
  const isIOS = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent);
  };

  useEffect(() => {
    if ("Notification" in window) {
      const permission = Notification.permission;
      if (permission === "granted") {
        setNotificationsEnabled(true);
      } else if (isIOS()) {
        requestNotificationPermission();
      }
    }

    if (user && user.user_info.email) {
      setGoogleLoginEnabled(true);
    }
  }, [user]);

  // طلب إذن الإشعارات من المستخدم
  const requestNotificationPermission = async () => {
    if ("Notification" in window) {
      try {
        triggerToast(
          "سيتم تفعيل الإشعارات! ستتلقى التحديثات هنا.",
          "info"
        );
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          triggerToast(
            "تم تفعيل الإشعارات! ستتلقى التحديثات هنا.",
            "success"
          );
          setNotificationsEnabled(true);

          // إرسال إشعار ترحيبي
          const notificationOptions = {
            body: "شكرًا لتفعيل الإشعارات. رحلتك معنا تبدأ الآن!",
            icon: "/logo192.png",
          };
          new Notification("المهندس أكادمي", notificationOptions);
        } else {
          const { browserName } = getBrowserInfo();
          let message = `تم رفض إذن الإشعارات. يرجى تمكينها لتلقي التحديثات.`;

          if (browserName === "جوجل كروم") {
            message = `للتفعيل، اذهب إلى الإعدادات > الخصوصية والأمان > إعدادات الموقع > الإشعارات.`;
          } else if (browserName === "موزيلا فايرفوكس") {
            message = `للتفعيل، اذهب إلى الخيارات > الخصوصية والأمان > الأذونات > الإشعارات.`;
          } else if (browserName === "سفاري") {
            message = `للتفعيل، اذهب إلى تفضيلات سفاري > المواقع الإلكترونية > الإشعارات.`;
          }

          triggerToast(message, "warning");
        }
      } catch (error) {
        console.error("خطأ في طلب إذن الإشعارات:", error);
        triggerToast("حدث خطأ أثناء طلب إذن الإشعارات.", "error");
      }
    } else {
      triggerToast("هذا المتصفح لا يدعم الإشعارات.", "info");
    }
  };

  // التعامل مع تسجيل الدخول بجوجل
  const handleGoogleLogin = async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const googleUser = result.user;

      if (!googleUser) {
        triggerToast(
          "فشل تسجيل الدخول بجوجل. يرجى المحاولة مرة أخرى.",
          "error"
        );
        return;
      }

      triggerToast(
        `مرحبًا، ${googleUser.displayName || "المستخدم"}! تم تسجيل الدخول بنجاح.`,
        "success"
      );

      if (user) {
        const updatedUser = {
          ...user,
          user_info: {
            ...user.user_info,
            email: googleUser.email || user.user_info.email,
            phone_number:
              googleUser.phoneNumber || user.user_info.phone_number,
          },
        };
        setUser(updatedUser);
        triggerToast(
          "تم تحديث الملف الشخصي بعد تسجيل الدخول.",
          "success"
        );
      }
    } catch (error: any) {
      console.error("فشل تسجيل الدخول بجوجل:", error.message);
      triggerToast(
        "فشل تسجيل الدخول بجوجل. يرجى المحاولة مرة أخرى.",
        "error"
      );
    }
  };

  return (
    <div
      dir="rtl"
      className="flex items-center justify-center min-h-screen bg-background font-cairo"
    >
      <div className="w-full max-w-md bg-white rounded-3xl shadow-xl p-6 animate-fadeIn">
        <h2 className="text-3xl font-extrabold text-center mb-6 text-textPrimary">
          مرحبًا بك في المهندس أكادمي!
        </h2>

        {/* عرض المتغيرات في بطاقات مرحة */}
        <div className="grid grid-cols-1 gap-4 mb-6">
          <Card className="bg-white shadow-lg animate-fadeIn">
            <CardContent>
              <Typography
                variant="h5"
                color="textPrimary"
                gutterBottom
                className="font-bold text-center text-textPrimary"
              >
                🛠️ ملفك الشخصي 🛠️
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>رقم العضوية:</strong>{" "}
                {user?.user_info.user_id || "غير متوفر"}
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>اسمك:</strong>{" "}
                {user?.user_info.username || "عضو جديد"}
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>بريدك الإلكتروني:</strong>{" "}
                {user?.user_info.email || "غير مرتبط"}
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>رقم الاتصال:</strong>{" "}
                {user?.user_info.phone_number || "غير معروف"}
              </Typography>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-lg animate-fadeIn">
            <CardContent>
              <Typography
                variant="h5"
                color="textPrimary"
                gutterBottom
                className="font-bold text-center text-textPrimary"
              >
                ⚙️ إعداداتك ⚙️
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>الإشعارات مفعلة:</strong>{" "}
                {notificationsEnabled ? "نعم" : "لا"}
              </Typography>
              <Typography variant="body1" className="text-textSecondary">
                <strong>تم ربط حساب جوجل:</strong>{" "}
                {googleLoginEnabled ? "نعم" : "لا"}
              </Typography>
            </CardContent>
          </Card>
        </div>

        {/* تفعيل تسجيل الدخول بجوجل */}
        <div className="flex items-center justify-between mb-4">
          <FormControlLabel
            labelPlacement="start"
            label="ربط حساب جوجل"
            control={
              <Switch
                checked={googleLoginEnabled}
                onChange={() => {
                  setGoogleLoginEnabled(!googleLoginEnabled);
                  if (!googleLoginEnabled) {
                    handleGoogleLogin();
                  }
                }}
                color="primary"
                icon={<Login />}
                checkedIcon={<CheckCircle />}
              />
            }
            className="text-textPrimary"
          />
        </div>

        {/* تفعيل الإشعارات */}
        <div className="flex items-center justify-between mb-4">
          <FormControlLabel
            labelPlacement="start"
            label="تفعيل الإشعارات"
            control={
              <Switch
                checked={notificationsEnabled}
                onChange={() => {
                  setNotificationsEnabled(!notificationsEnabled);
                  if (!notificationsEnabled) {
                    requestNotificationPermission();
                  }
                }}
                color="secondary"
                icon={<NotificationsActive />}
                checkedIcon={<CheckCircle />}
              />
            }
            className="text-textPrimary"
          />
        </div>

        {/* تقدم التحميل */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mt-4">
            <div className="text-sm text-textSecondary mb-2">
              تحميل صورتك الشخصية: {uploadProgress}%
            </div>
            <div className="w-full bg-gray-300 rounded-full h-2.5">
              <div
                className="bg-primary h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationSettings;
