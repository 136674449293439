import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { WhatsApp, School, LaptopMac } from "@mui/icons-material"; // استيراد أيقونات واتساب وحصة ومنصة

const SupportNotification: React.FC = () => {
  const [hovered, setHovered] = useState(false);
  const [message, setMessage] = useState("");
  const [supportType, setSupportType] = useState<"session" | "platform">("session");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const encodedMessage = encodeURIComponent(message);
      const phoneNumber =
        supportType === "session"
          ? "+201097430973" // مهندس حصة
          : "+201142603449"; // دعم المنصة أو تفعيل الاشتراك
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.open(whatsappUrl, "_blank");
    }
  };

  return (
    <div
      className="fixed bottom-5 right-5 z-50  " dir="rtl"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* WhatsApp Icon */}
      <motion.div
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        className="bg-green-500 text-white p-3 rounded-full shadow-lg cursor-pointer"
      >
        <WhatsApp className="text-2xl" />
      </motion.div>

      {/* Animated Message on Hover */}
      <AnimatePresence>
        {hovered && (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 50 }}
            transition={{ duration: 0.3 }}
            className="absolute bottom-0 right-14 p-3 bg-white text-gray-800 rounded-lg shadow-lg flex flex-col gap-3 border-2 border-green-500 min-w-[300px]"
          >
            <span className="text-sm font-semibold">كيف يمكننا مساعدتك؟ اختر نوع الدعم</span>
            
            {/* Dropdown to select support type */}
            <div className="flex gap-3 mb-2">
              <div
                onClick={() => setSupportType("session")}
                className={`flex flex-col items-center cursor-pointer ${supportType === "session" ? "text-green-500" : ""}`}
              >
                <School className="text-xl" />
                <span className="text-xs">الدعم في الحصة</span>
              </div>
              <div
                onClick={() => setSupportType("platform")}
                className={`flex flex-col items-center cursor-pointer ${supportType === "platform" ? "text-green-500" : ""}`}
              >
                <LaptopMac className="text-xl" />
                <span className="text-xs">الدعم في المنصة</span>
              </div>
            </div>
            
            {/* Input to type the message */}
            <input
              type="text"
              placeholder="اكتب رسالتك هنا"
              value={message}
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md p-2 text-sm w-full"
            />
            
            {/* Send Message Button */}
            <button
              onClick={handleSendMessage}
              className="bg-green-500 text-white px-4 py-2 rounded-md text-sm mt-2"
            >
              إرسال إلى واتساب
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SupportNotification;
