import React, { useState, useRef } from "react";
import { Session } from "../../context/CourseContext";

interface SessionListProps {
  sessions: Session[];
  activeSession: Session | null;
  setActiveSession: (session: Session | null) => void;
}

const SessionList: React.FC<SessionListProps> = ({
  sessions,
  activeSession,
  setActiveSession,
}) => {
  const [hoveredSession, setHoveredSession] = useState<Session | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const sessionRefs = useRef<Map<number, HTMLDivElement>>(new Map());
  const tableRef = useRef<HTMLDivElement | null>(null);

  const now = new Date();

  const handleSessionClick = (session: Session) => {
    if (activeSession?.id === session.id) {
      setActiveSession(null);
    } else {
      setActiveSession(session);
      setTimeout(() => {
        tableRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 300);
    }
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>, session: Session) => {
    if (hoverTimeoutRef.current) clearTimeout(hoverTimeoutRef.current);
    const sessionElement = sessionRefs.current.get(session.id);
    if (sessionElement) {
      const { top, left } = sessionElement.getBoundingClientRect();
      setTooltipPosition({
        top: top - 35,
        left: left + sessionElement.offsetWidth / 2,
      });
    }

    hoverTimeoutRef.current = setTimeout(() => {
      setHoveredSession(session);
      setShowTooltip(true);
    }, 3000);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) clearTimeout(hoverTimeoutRef.current);
    setHoveredSession(null);
    setShowTooltip(false);
  };

  const getSessionStatus = (session: Session) => {
    const sessionDate = new Date(session.start_time);
    if (sessionDate < now) return "past";
    if (sessionDate > now) return "upcoming";
    return "active";
  };

  const renderSession = (session: Session, index: number) => {
    const status = getSessionStatus(session);
    const isActive = activeSession?.id === session.id;

    const statusStyles = {
      past: "bg-error text-white border-error",
      upcoming: "bg-secondary text-white border-secondary",
      active: "bg-primary text-white border-primary",
    };

    const activeStyles = isActive
      ? "shadow-lg border-4 border-primary bg-gradient-to-r from-secondary to-primary text-white"
      : "";

    return (
      <div
        key={session.id}
        className="relative"
        onMouseEnter={(e) => handleMouseEnter(e, session)}
        onMouseLeave={handleMouseLeave}
        ref={(el) => el && sessionRefs.current.set(session.id, el)}
      >
        {showTooltip && hoveredSession?.id === session.id && (
          <div
            className="absolute bg-white shadow-lg rounded-md p-4 text-sm z-10"
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              transform: "translateX(-50%)",
            }}
            dir="rtl"
          >
            <h5 className="font-bold mb-2">{session.title}</h5>
            <p className="mb-2">{session.description}</p>
            <p className="mb-2">المهام:</p>
            {session.get_tasks && session.get_tasks.length > 0 ? (
              <ul className="list-disc pr-5 text-gray-700">
                {session.get_tasks.map((task, taskIndex) => (
                  <li key={taskIndex}>{task.title}</li>
                ))}
              </ul>
            ) : (
              <p>لا توجد مهام.</p>
            )}
          </div>
        )}

        <button
          className={`session-item relative w-full py-2 px-4 rounded-xl border-2 text-sm font-medium transition-all duration-500 ease-in-out transform hover:scale-105 ${
            statusStyles[status]
          } ${activeStyles}`}
          onClick={() => handleSessionClick(session)}
        >
          {` ${session.title}`}
          <span className="w-6 h-6 text-[12px] -left-2 -top-2 bg-primary border-error text-background absolute rounded-full text-center p-1">
            {index + 1}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="session-list mt-4 transition-all duration-1000 ease-in-out animate-fadeIn text-right">
      <h4 className="font-semibold text-lg mb-3">الجلسات</h4>
      <div className="space-y-2 relative">
        {activeSession
          ? renderSession(activeSession, sessions.findIndex((s) => s.id === activeSession.id))
          : sessions.map((session, index) => renderSession(session, index))}
      </div>
      <div ref={tableRef}></div>
    </div>
  );
};

export default SessionList;
