import React from "react";
import { Session } from "../../context/CourseContext";
import { getFormattedStartTime } from "../../components/Common/getFormattedStartTime";
import { getSessionStatusDetails } from "../../components/Common/getSessionStatusDetails";
import { useAskuser } from "../../context/AskInstructorContext";
import ChildAvatar from "../../components/ChildAvatar";

interface SessionDetailsHeaderProps {
  session: Session;
}

const SessionDetailsHeader: React.FC<SessionDetailsHeaderProps> = ({
  session,
}) => {
  const isGoogleDriveVideo =
    session.meeting_link && session.meeting_link.includes("drive.google");

  const {
    selectedChildId,
    selectedChildName,
    taskToAsk,
    openAskuserModal,
    closeAskuserModal,
    userRole,
  } = useAskuser();

  const handleAskuserClick = () => {
    openAskuserModal(session.id, selectedChildName);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start bg-gradient-to-r gap-2 from-white to-background p-6 rounded-2xl text-right font-cairo">
      {/* تفاصيل المحاضرة */}
      <div className="flex flex-col w-full ">
        <h4 className="text-2xl md:text-3xl font-semibold text-primary mb-2 lg:mb-4">
          {session.title}
        </h4>
        <p className="text-base md:text-lg text-secondary mb-2 lg:mb-4">
          {session.description || "لا يوجد وصف لهذه المحاضرة"}
        </p>

        {/* عرض وقت البدء */}
        <div className="flex flex-col md:flex-row md:items-center text-sm text-secondary mt-2">
          <p className="font-semibold text-primary md:mr-2">الوقت الفعلي للبدء:</p>
          <p className="text-base md:text-lg text-accent font-bold">
            {getFormattedStartTime(session)}
          </p>
        </div>

        {/* تفاصيل أكثر */}
        <div className="flex flex-col md:flex-row md:items-center text-sm text-secondary mt-2">
          <p className="font-semibold text-primary md:mr-2">تفاصيل أكثر:</p>
          <p className="text-base md:text-lg text-accent font-bold">
            {getSessionStatusDetails(session)}
          </p>
        </div>

        {/* رابط الاجتماع أو الفيديو */}
        <div className="mt-4">
          {session.meeting_link && (
            <a
              href={session.meeting_link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-primary text-white font-semibold rounded-lg shadow-md transition-transform transform hover:scale-105"
            >
              {isGoogleDriveVideo ? "شاهد الفيديو" : "انضم إلى الاجتماع"}
            </a>
          )}
        </div>

        {/* زر اسأل المدرب */}
        <button
          onClick={handleAskuserClick}
          className="mt-4 px-4 py-2 bg-accent text-white font-semibold rounded-lg shadow-md transition-transform transform hover:scale-105"
        >
          اسأل المدرب
        </button>
      </div>

      {/* صور الأطفال */}
      <div className="flex flex-wrap w-full  mt-6 lg:mt-0 justify-center lg:justify-end">
        <ChildAvatar />
      </div>
    </div>
  );
};

export default SessionDetailsHeader;
