import React, { useContext, useEffect } from "react";
import CourseSessionPreview from "../Courses/CourseSessionPreview";
import { CourseContext } from "../../context/CourseContext";
import SessionCard from "../../components/SessionCard";
import CoursePerformance from "../../components/CoursePerformance";
import Loader from "../../components/Loader";

const UserDashboard: React.FC = () => {
  const courseContext = useContext(CourseContext);
  const [is_loader,setIs_loader] = React.useState(false); // Add state to manage loading status[
  const courses = courseContext?.courses ?? [];
  const activeCourse = courseContext?.activeCourse;
  const handleSelectCourse = courseContext?.handleSelectCourse;
  const exactUpcomingSession = courseContext?.exactUpcomingSession;
  const is_loading = courseContext?.is_loading;

  useEffect(() => {
    if (courses.length > 0 && !activeCourse && handleSelectCourse) {
      handleSelectCourse(courses[0]); // تحديد الدورة الأولى تلقائيًا إذا لم تكن محددة
    }
    if (is_loading){
      setIs_loader(true)
    }else{
      setIs_loader(false)
    } 
  }, [courses, activeCourse,]);

  useEffect(() => {
    console.log("Upcoming Session:", exactUpcomingSession);
  }, [exactUpcomingSession]);

  if (is_loader) {
    return (
      <div className="text-center text-gray-500 flex flex-col h-[120px] mt-32">
      <Loader />  {/* Use the Loader component */}
      جارٍ تحميل بيانات الدورات من الخادم... يسعدنا تواجدكم معنا.
    </div>
    );
  }
  if (!courseContext) {
    return (
      <div role="alert" className="text-red-500 text-center mt-5">
        خطأ: CourseContext غير معرّف. يرجى التأكد من أن المزوّد يلتف حول هذا المكوّن.
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background text-primary p-4 sm:p-8">
      {/* معاينة الجلسة القادمة */}
      {exactUpcomingSession ? (
        <div key={exactUpcomingSession.id} className="mb-8 min-h-[200px] h-auto">
          <div className="flex flex-col lg:flex-row items-start lg:items-stretch bg-white shadow-lg rounded-lg overflow-visible gap-5">
            <div className="relative">
              <SessionCard session={exactUpcomingSession} />
            </div>
            <div className="bg-gray-50 p-4">
              <CoursePerformance />
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">لا توجد جلسات قادمة</p>
      )}

      {/* معاينة الدورات */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4 text-textPrimary">
          الدورات المتاحة
        </h2>
        <CourseSessionPreview />
      </div>
    </div>
  );
};

export default UserDashboard;
