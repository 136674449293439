import React, { useEffect, useState } from "react";
import {
  Timer,
  CalendarToday,
  AccessTime,
  VideoCall,
  HourglassEmpty,
  EventAvailable,
  AccessAlarms,
} from "@mui/icons-material";
import { Session } from "../context/CourseContext";
import { motion } from "framer-motion";

interface SessionCardProps {
  session: Session;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const [timeDisplay, setTimeDisplay] = useState({
    countdown: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      status: "لم تبدأ الجلسة",
    },
    egyptStartTime: "",
    saudiStartTime: "",
    gmtStartTime: "",
    currentGmtTime: "", // الوقت الحالي بتوقيت GMT
    statusColor: "text-blue-500", // اللون الافتراضي
  });

  const SAUDI_OFFSET = 3 * 60 * 60 * 1000; // تعويض السعودية بالمللي ثانية
  const EGYPT_OFFSET = 2 * 60 * 60 * 1000; // تعويض مصر بالمللي ثانية

  useEffect(() => {
    const updateTimers = () => {
      const nowGMT = new Date().getTime(); // الوقت الحالي بتوقيت GMT بالمللي ثانية
      const startTimeGMT = new Date(session.start_time).getTime(); // وقت بدء الجلسة بتوقيت GMT

      const timeLeft = startTimeGMT - nowGMT;

      // دالة لتنسيق الوقت إلى أيام، ساعات، دقائق، وثواني
      const formatTime = (timeInMs: number) => {
        const days = Math.floor(timeInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeInMs % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeInMs % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds };
      };

      const countdown = formatTime(Math.abs(timeLeft));
      let status = "الجلسة بدأت";
      let statusColor = "text-red-500"; // اللون الافتراضي إذا الجلسة بدأت

      if (timeLeft > 0) {
        status = "لم تبدأ الجلسة";

        if (timeLeft <= 600000) {
          // أقل من أو يساوي 10 دقائق
          statusColor = "text-green-500";
        } else if (timeLeft <= 86400000) {
          // أقل من أو يساوي 24 ساعة
          statusColor = "text-yellow-500";
        } else {
          statusColor = "text-blue-500";
        }
      }

      // حساب وقت البدء لكل منطقة
      const egyptStartTime = new Date(
        startTimeGMT + EGYPT_OFFSET
      ).toLocaleString("ar-EG", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      const saudiStartTime = new Date(
        startTimeGMT + SAUDI_OFFSET
      ).toLocaleString("ar-SA", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      // وقت بدء الجلسة بتوقيت GMT
      const gmtStartTime = new Date(startTimeGMT).toLocaleString("en-GB", {
        timeZone: "GMT",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      // الوقت الحالي بتوقيت GMT
      const currentGmtTime = new Date(nowGMT).toLocaleString("en-GB", {
        timeZone: "GMT",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      setTimeDisplay({
        countdown: { ...countdown, status },
        egyptStartTime,
        saudiStartTime,
        gmtStartTime,
        currentGmtTime,
        statusColor,
      });
    };

    updateTimers();
    const interval = setInterval(updateTimers, 1000);
    return () => clearInterval(interval);
  }, [session.start_time]);

  return (
    <div
      className="p-6 bg-white rounded-xl flex flex-col gap-4 border border-gray-200 shadow-md hover:shadow-xl transition-shadow duration-300 font-cairo"
      dir="rtl"
    >
      {/* عنوان الجلسة */}
      <h2 className="text-2xl font-bold mb-2 text-textPrimary">
        {session.title || "عنوان الجلسة"}
      </h2>

      {/* وصف الجلسة */}
      <p className="text-gray-600 text-sm">
        {session.description || "لا يوجد وصف لهذه الجلسة."}
      </p>

      {/* العداد التنازلي */}
      <div
        className={`flex items-center gap-2 p-4 rounded-lg ${
          timeDisplay.statusColor === "text-red-500"
            ? "bg-red-50"
            : timeDisplay.statusColor === "text-green-500"
            ? "bg-green-50"
            : timeDisplay.statusColor === "text-yellow-500"
            ? "bg-yellow-50"
            : "bg-blue-50"
        }`}
      >
        <Timer className={`${timeDisplay.statusColor} text-3xl`} />
        <div className="flex flex-col items-center w-full">
          <div className="flex items-center gap-4 text-lg font-bold">
            {["days", "hours", "minutes", "seconds"].map((unit, index) => (
              <div key={unit} className="flex flex-col items-center">
                <motion.span
                  className="text-xl"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  key={
                    timeDisplay.countdown[
                      unit as keyof typeof timeDisplay.countdown
                    ]
                  }
                >
                  {String(
                    timeDisplay.countdown[
                      unit as keyof typeof timeDisplay.countdown
                    ]
                  ).padStart(2, "0")}
                </motion.span>
                <span className="text-sm text-gray-500">
                  {unit === "days"
                    ? "أيام"
                    : unit === "hours"
                    ? "ساعات"
                    : unit === "minutes"
                    ? "دقائق"
                    : "ثواني"}
                </span>
              </div>
            ))}
          </div>
          <div
            className={`text-sm font-medium mt-2 ${timeDisplay.statusColor}`}
          >
            {timeDisplay.countdown.status}
          </div>
        </div>
      </div>

      {/* الوقت الحالي بتوقيت GMT */}
      <div className="mt-2 flex items-center gap-2">
        <AccessAlarms className="text-primary" />
        <span className="text-sm text-gray-700">
          الوقت الحالي (GMT): {timeDisplay.currentGmtTime}
        </span>
      </div>

      {/* وقت بدء الجلسة المستخدم في الحسابات */}
      <div className="mt-2 flex items-center gap-2">
        <EventAvailable className="text-primary" />
        <span className="text-sm text-gray-700">
          وقت بدء الجلسة (GMT): {timeDisplay.gmtStartTime}
        </span>
      </div>

      {/* أوقات البدء */}
      <div className="mt-2 flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <AccessTime className="text-primary" />
          <span className="text-sm text-gray-700">
            وقت البدء في مصر: {timeDisplay.egyptStartTime}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <AccessTime className="text-primary" />
          <span className="text-sm text-gray-700">
            وقت البدء في السعودية: {timeDisplay.saudiStartTime}
          </span>
        </div>
      </div>

      {/* زر الانضمام للجلسة */}
      {session.meeting_link ? (
        <a
          href={session.meeting_link}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 flex items-center justify-center gap-2 bg-primary text-white text-sm px-4 py-2 rounded-lg hover:bg-primary-dark transition"
        >
          <VideoCall className="text-white" />
          <span>انضم إلى الجلسة</span>
        </a>
      ) : (
        <div className="mt-4 text-sm text-red-500 flex items-center gap-2">
          <HourglassEmpty className="animate-spin" />
          <span>نحن في انتظار المعلم لإضافة رابط الجلسة.</span>
        </div>
      )}
    </div>
  );
};

export default SessionCard;
