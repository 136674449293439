import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { ToastFunction } from "./context/ToastContext";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBjmLt5w_6LtndPjITo__LlLg3HQ-PNQ6A",
  authDomain: "elmohandes-academy-e0e53.firebaseapp.com",
  projectId: "elmohandes-academy-e0e53",
  storageBucket: "elmohandes-academy-e0e53.firebasestorage.app",
  messagingSenderId: "950163802077",
  appId: "1:950163802077:web:2a402d30834ee059f05834",
  measurementId: "G-YWMQJ05PQC",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase messaging
const messaging = getMessaging(app);

export const initializeNotifications = async (
  user: { id: string; token: { access: string } } | null,
  triggerToast: ToastFunction // Add triggerToast as parameter
): Promise<void> => {
  try {
    // Register the service worker
    const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");

    // Request notification permission
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      triggerToast("Notifications enabled!", "success");

      // Get the FCM token
      const currentToken = await getToken(messaging, {
        vapidKey: "BFLF5luNDnu_jd-GrUpHnvhzGViZwxA5HPCXDqvXHK-J2bLT0pPoNaSZnJsxY9cxnTTivLUHEEYXVHhSWQxg9pI",
        serviceWorkerRegistration: registration,
      });

      if (currentToken) {
        if (user) {
          try {
            const userId = user.id;

            await fetch("https://codeoceantech.pythonanywhere.com/api/notifications/token/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token.access}`,
              },
              body: JSON.stringify({
                userId,
                fcm_token: currentToken,
              }),
            });

            triggerToast("Notification token registered with the server.", "success");
          } catch (error) {
            console.error("Error sending FCM token to backend:", error);
            triggerToast("Failed to register notification token with the server.", "error");
          }
        } else {
          triggerToast("Notifications are active but not tied to a user.", "warning");
        }
      } else {
        console.error("No registration token available.");
        triggerToast("Failed to generate notification token.", "error");
      }
    } else {
      console.warn("Notification permission denied.");
      triggerToast("Please enable notifications in browser settings.", "warning");
    }

    // Listen for foreground messages
    onMessage(messaging, (payload) => {
      triggerToast(`New Notification: ${payload.notification?.title}`, "info");
    });
  } catch (error) {
    console.error("Error initializing notifications:", error);
    triggerToast("Error initializing notifications - check console.", "error");
  }
};