import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";

type NotificationProps = {
  message: string;
  type?: "success" | "error" | "info" | "warning";
};

const borderVariants = {
  success: {
    borderColor: ["#28a745", "#218838", "#28a745"],
    transition: { duration: 1, repeat: Infinity, ease: "easeInOut" },
  },
  error: {
    borderColor: ["#dc3545", "#c82333", "#dc3545"],
    transition: { duration: 1, repeat: Infinity, ease: "easeInOut" },
  },
  info: {
    borderColor: ["#007bff", "#0056b3", "#007bff"],
    transition: { duration: 1, repeat: Infinity, ease: "easeInOut" },
  },
  warning: {
    borderColor: ["#ffc107", "#e0a800", "#ffc107"],
    transition: { duration: 1, repeat: Infinity, ease: "easeInOut" },
  },
};

const getIcon = (type: string) => {
  switch (type) {
    case "success":
      return <CheckCircle className="text-green-600 text-3xl" />;
    case "error":
      return <Error className="text-red-600 text-3xl" />;
    case "info":
      return <Info className="text-blue-600 text-3xl" />;
    case "warning":
      return <Warning className="text-yellow-600 text-3xl" />;
    default:
      return null;
  }
};

const FixedNotification: React.FC<NotificationProps> = ({
  message,
  type = "info",
}) => {
  const whatsappMessage = encodeURIComponent(
    "Hello, I need help with something."
  );

  return (
    <div
      className="fixed top-5 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md p-4 max-sm:w-[300px]"
      dir="rtl"
    >
      <AnimatePresence>
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.3 }} // Reduced animation duration
          className={`p-4 mb-2 text-white rounded-lg shadow-lg flex justify-between items-center w-full  relative border-4`}
          style={{ backgroundColor: borderVariants[type].borderColor[0] }}
        >
          {/* Animated Border */}
          <motion.div
            className="absolute inset-0  border-4 rounded-lg"
            animate={borderVariants[type]}
          />

          <div className="flex flex-row items-center gap-2 relative z-10">
            {getIcon(type)}
            <span className="text-sm font-medium">{message}</span>
          </div>

          {/* WhatsApp Button */}
          <div className="flex flex-col justify-center gap-3 relative z-10 mt-2">
              <div className="flex flex-col  items-center justify-center align-middle   text-white text-xs text-center">
            <a
              href={`https://wa.me/201229058695?text=${whatsappMessage}`}
              className="bg-green-600 p-3 rounded-full shadow-lg hover:bg-green-700 transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/800px-WhatsApp.svg.png"
                alt="WhatsApp"
                className="w-8 h-8"
              />
            </a>
                <span>اضغط هنا للتواصل</span>
              </div>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* "اضغط هنا للتواصل" text under the icon */}
    </div>
  );
};

export default FixedNotification;
