import React, { createContext, useState, useEffect, useContext } from "react";
import { useLocalStorage } from "./LocalStorageContext";

export interface Subscription {
  id: string;
  start_date: string;
  end_date: string;
}

interface SubscriptionContextType {
  subscriptions: Subscription[];
  loading: boolean;
  is_subscribed: boolean;
  days_to_finish: number;
  is_near_expiry: boolean;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);
  const { value: user } = useLocalStorage<{ token: { access: string } }>();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (!user?.token?.access) return;
      try {
        const response = await fetch(
          "https://codeoceantech.pythonanywhere.com/api/accounts/subscription/check/",
          {
            headers: {
              Authorization: `Bearer ${user.token.access}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch subscriptions");
        const data = await response.json();
        console.log("API Response:", data); // Debugging log
        setSubscriptions(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setSubscriptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, [user]);

  const today = new Date();
  const activeSubscription = subscriptions.find(sub => new Date(sub.end_date) >= today);
  const is_subscribed = !!activeSubscription;
  const days_to_finish = activeSubscription 
    ? Math.max(0, Math.ceil((new Date(activeSubscription.end_date).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)))
    : 0;
  const is_near_expiry = days_to_finish > 0 && days_to_finish <= 10;

  return (
    <SubscriptionContext.Provider value={{ subscriptions, loading, is_subscribed, days_to_finish, is_near_expiry }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error("useSubscription must be used within a SubscriptionProvider");
  }
  return context;
};
