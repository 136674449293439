import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Home,
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Description,
  ExitToApp,
  Refresh,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import NotificationToast from "./NotificationToast";
import { AuthContext } from "./context/AuthContext";
import ActivitiesNavbar from "./components/Common/ActivitiesNavbar";
import CoursePerformance from "./components/CoursePerformance"; // Importing CoursePerformance
import { Subscription, useSubscription } from "./context/SubscriptionContext";
import FixedNotification from "./FixedNotification";
import SupportNotification from "./SupportNotification";

// Function to handle page refresh
const handleRefresh = () => {
  window.location.reload();
};

// Sidebar Component
const Sidebar: React.FC<{ isOpen: boolean; toggleSidebar: () => void }> = ({
  isOpen,
  toggleSidebar,
}) => {
  const authContext = useContext(AuthContext);
  const logout = authContext?.logout;
  const { is_subscribed, is_near_expiry, days_to_finish } = useSubscription();
  const subscriptionColor = is_subscribed
    ? is_near_expiry
      ? "bg-yellow-500" // Yellow for near expiry
      : "bg-green-500" // Green for active subscriptions
    : "bg-red-500"; // Red for inactive subscription

  return (
    <motion.aside
      className={`transition-transform duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full w-[1px]"
      } ${subscriptionColor} text-textBackground h-full py-4 px-1 max-sm:px-0  `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Sidebar Toggle Button for Mobile */}
      <div className="flex justify-between items-center mb-8">
        <button
          className="md:hidden text-textBackground hover:text-gray-300"
          onClick={toggleSidebar}
        >
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </button>
      </div>

      {/* Navigation Menu */}
      {isOpen && (
        <nav>
          <ul className="space-y-6">
            {[
              { to: "/user/dashboard", icon: <Home />, label: "الرئيسية" },
              {
                to: "/user/profile",
                icon: <AccountCircle />,
                label: "الملف الشخصي",
              },
              {
                to: "/user/notificationSettings/5",
                icon: <Description />,
                label: "إعدادات الإشعارات",
              },
            ].map((item, index) => (
              <motion.li
                key={item.to}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.1 * (index + 1) }}
              >
                <Link
                  to={item.to}
                  className="flex items-center py-3 px-5 hover:bg-accent rounded-md transition duration-200"
                >
                  {item.icon}
                  <span className="ml-3 text-lg text-textBackground">
                    {item.label}
                  </span>
                </Link>
              </motion.li>
            ))}

            {/* Logout Button */}
            <motion.li
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <button
                onClick={logout}
                className="flex items-center w-full py-3 px-5 hover:bg-error rounded-md transition duration-200 text-error"
              >
                <ExitToApp />
                <span className="ml-3">تسجيل الخروج</span>
              </button>
            </motion.li>
          </ul>
        </nav>
      )}
    </motion.aside>
  );
};

// Main Content Component
interface MainContentProps {
  children: React.ReactNode;
  isContentVisible: boolean;
  isSidebarOpen: boolean;
  isActivitiesOpen: boolean;
  toggleContentVisibility: () => void;
  toggleActivities: () => void;
  toggleSidebar: () => void;
}

const MainContent: React.FC<MainContentProps> = ({
  children,
  isContentVisible,
  toggleContentVisibility,
  isSidebarOpen,
  toggleSidebar,
  isActivitiesOpen,
  toggleActivities,
}) => {
  const { is_subscribed, is_near_expiry, days_to_finish } = useSubscription();
  const subscriptionColor = is_subscribed
    ? is_near_expiry
      ? "bg-yellow-500" // Yellow for near expiry
      : "bg-green-500" // Green for active subscriptions
    : "bg-red-500"; // Red for inactive subscription

  return (
    <main
      className={`relative w-full flex justify-center m-auto bg-background h-full p-8 max-sm:px-1 overflow-auto overflow-x-hidden ${
        isContentVisible ? "" : "hidden"
      }`}
    >
       {!is_subscribed && (
        <FixedNotification message="أنت غير مشترك، يرجى الاشتراك للوصول الكامل." type="error" />
      )}
      {is_subscribed && is_near_expiry && (
        <FixedNotification message="اشتراكك سينتهي قريبًا، قم بالتجديد الآن!" type="warning" />
      )}
      {/* Notification Toast */}
      <SupportNotification  />
      {!isActivitiesOpen && <NotificationToast />}

      {/* Dynamic Content */}
      <motion.div
        className="rounded-lg max-sm:scale-90 max-xs:scale-75"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>

      {/* Sidebar Toggle Button if Closed */}
      {!isSidebarOpen && (
        <button
          className="absolute top-4 left-4 p-2 text-textBackground bg-primary rounded-md"
          onClick={toggleSidebar}
        >
          <ChevronRight />
        </button>
      )}

      {/* Course Performance Component */}
      {/* <CoursePerformance /> */}
    </main>
  );
};

// Layout Component
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const [isActivitiesOpen, setIsActivitiesOpen] = useState<boolean>(false);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const toggleActivities = () => setIsActivitiesOpen((prev) => !prev);
  const toggleContentVisibility = () => setIsContentVisible((prev) => !prev);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100 max-sm:flex">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <MainContent
        isContentVisible={isContentVisible}
        toggleContentVisibility={toggleContentVisibility}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isActivitiesOpen={isActivitiesOpen}
        toggleActivities={toggleActivities}
      >
        {/* Activities Navbar */}
        <ActivitiesNavbar
          isOpen={isActivitiesOpen}
          toggleActivities={toggleActivities}
        />

        {/* Children Components */}
        {children}
      </MainContent>

      {/* Refresh Button for Mobile */}
      <button
        onClick={handleRefresh}
        className="sm:hidden fixed bottom-4 right-4 p-4 bg-primary text-textBackground rounded-full shadow-md hover:bg-accent transition duration-200"
      >
        <Refresh />
      </button>
    </div>
  );
};

export default Layout;
