import React, { useContext } from "react";
import { CourseContext, Session } from "../context/CourseContext";
import { motion } from "framer-motion";

const ChildAvatarList: React.FC = () => {
  const courseContext = useContext(CourseContext);

  if (!courseContext) {
    return (
      <div className="text-red-500">
        خطأ: CourseContext غير معرّف. يرجى التحقق من إعداد المزود.
      </div>
    );
  }

  const {
    activeSessionChildren,
    activeSessionChildrenAbsent,
    activeSessionChildrenAttendance,
    activeSession,
  } = courseContext;

  const getTotalDegreeForChildInSession = (targetChild: any, session: Session): number => {
    let totalDegree = 0;
    courseContext.activeSessionChildrenTasks?.forEach((task) => {
      task.children?.forEach((child) => {
        if (child.id === targetChild.id) {
          totalDegree += child.degree || 0;
        }
      });
    });
    return totalDegree;
  };

  const getHeartsForChild = (targetChild: any, session: Session): string => {
    const totalDegree = getTotalDegreeForChildInSession(targetChild, session);
    return "❤️".repeat(totalDegree);
  };

  const percentages = activeSessionChildren.map((child) => ({
    child,
    percentage: getTotalDegreeForChildInSession(child, activeSession!),
  }));

  const sortedByPercentage = percentages.sort((a, b) => b.percentage - a.percentage);

  let rank = 1;
  const rankedChildren = sortedByPercentage.map((current, index, array) => {
    if (index > 0 && current.percentage < array[index - 1].percentage) {
      rank = index + 1;
    }
    return { ...current, rank };
  });

  const tableId = `child-avatar-list-${activeSession?.id || "default"}`;

  return (
    <div className="max-h-80 overflow-y-auto" dir="rtl">
      <table id={tableId} className="min-w-full leading-normal">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="px-5 py-3 border-b-2 border-gray-300 text-left">الترتيب</th>
            <th className="px-5 py-3 border-b-2 border-gray-300 text-left">الاسم</th>
            <th className="px-5 py-3 border-b-2 border-gray-300 text-left">الدرجة</th>
          </tr>
        </thead>
        <tbody>
          {rankedChildren.map(({ child, percentage, rank }) => {
            const isInAttendance = activeSessionChildrenAttendance?.some((c) => c.id === child.id);
            const isAbsent = activeSessionChildrenAbsent?.some((c) => c.id === child.id);
            const bgColor = isInAttendance ? "bg-green-100" : isAbsent ? "bg-red-100" : "bg-white";

            return (
              <motion.tr
                key={child.id}
                className={`border-b border-gray-200 hover:bg-gray-100 ${bgColor}`}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: "spring", stiffness: 100 }}
              >
                <td className="px-5 py-5 text-sm">
                  <div className="flex items-center">
                    <div className="text-gray-900 whitespace-no-wrap">{rank}</div>
                    {rank <= 3 && (
                      <div className="ml-2">
                        {rank === 1 ? "🥇" : rank === 2 ? "🥈" : "🥉"}
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-5 py-5 text-sm text-gray-900">{child.name || "بدون اسم"}</td>
                <td className="px-5 py-5 text-sm text-gray-900">
                  {activeSession && getHeartsForChild(child, activeSession)}
                </td>
              </motion.tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChildAvatarList;
