import React, { useState, useEffect } from "react";
import DynamicBackground from "./DynamicBackground";

interface ProfileCardProps {
  name?: string;
  description: string;
  profile_image?: string;
  iconUrl?: string;
  backgroundOpacity?: number;
  cardBorderColor?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name = "User",
  description,
  profile_image,
  iconUrl,
  backgroundOpacity = 0.6,
  cardBorderColor = "#e8eaee",
}) => {
  const defaultProfileImage = "/logo.jpg"; // Default profile image from public folder
  const defaultIcon = "/logo.jpg"; // Default icon from public folder

  const [profileData, setProfileData] = useState({
    name,
    description,
    profile_image: profile_image || defaultProfileImage,
    iconUrl: iconUrl || defaultIcon,
  });

  useEffect(() => {
    setProfileData({
      name,
      description,
      profile_image: profile_image || defaultProfileImage,
      iconUrl: iconUrl || defaultIcon,
    });
  }, [name, description, profile_image, iconUrl]);

  return (
    <div className="w-full px-4 md:px-8 mb-8 flex flex-col justify-start items-center">
      {/* Top border and background */}
      <div className="w-full flex flex-col justify-start items-center gap-[3px] mb-6">
        <div
          className="w-full border-t-2"
          style={{ borderColor: cardBorderColor }}
        ></div>
        <div
          className="h-[146px] max-h-[146px] w-full relative"
          style={{ opacity: backgroundOpacity }}
        >
          <DynamicBackground />
        </div>
        <div
          className="w-full border-b-2"
          style={{ borderColor: cardBorderColor }}
        ></div>
      </div>

      {/* Profile Image and Text Container */}
      <div className="w-full flex flex-col md:flex-row justify-start items-center gap-8 md:gap-12">
        {/* Profile Image */}
        <div className="w-[150px] h-[150px] md:w-[180px] md:h-[180px] relative mb-4 md:mb-0">
          <div className="w-full h-full relative">
            <img
              className="w-full h-full rounded-full object-cover transition-transform duration-300 transform hover:scale-105"
              src={profileData.profile_image}
              alt={`${profileData.name}'s Avatar`}
            />
            <div className="w-full h-full absolute top-0 left-0 rounded-full shadow-2xl border-8 border-[#26b893]/50 transition-transform duration-300 transform hover:scale-105"></div>
          </div>
          <div className="w-8 h-8 md:w-10 md:h-10 absolute bottom-0 right-0 bg-[#26b893] rounded-full p-[2px] shadow-lg">
            <div className="w-6 h-6 md:w-8 md:h-8 flex justify-center items-center">
              <img
                className="w-6 h-6 md:w-6 md:h-6 rounded-full"
                src={profileData.iconUrl}
                alt="Profile Icon"
              />
            </div>
          </div>
        </div>

        {/* Profile Text */}
        <div className="flex flex-col justify-start items-start md:items-start gap-4 w-full">
          <div className="text-[#083a50] text-xl md:text-2xl font-bold font-['Nunito'] text-center md:text-left">
            {profileData.name}
          </div>
          <div className="text-[#083a50] text-lg md:text-xl leading-relaxed text-center md:text-left">
            {profileData.description}
          </div>
        </div>
      </div>

      {/* Bottom border */}
      <div
        className="w-full border-b-2 mt-6"
        style={{ borderColor: cardBorderColor }}
      ></div>
    </div>
  );
};

export default ProfileCard;
