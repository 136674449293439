import React, { useState, useEffect } from "react";
import { useActivities } from "../../context/ActivitiesContext";
import { useSubscription } from "../../context/SubscriptionContext";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useNotification from "../../context/useNotification";

export interface Activity {
  id: number;
  user: number;
  title: string;
  description: string;
  activity_type: string;
  child_id: number;
  child_name: string;
  task_id: number;
  is_seen: boolean;
  is_loading?: boolean;
  link?: string;
}

interface ActivitiesNavbarProps {
  isOpen: boolean;
  toggleActivities: () => void;
}

const ActivitiesNavbar: React.FC<ActivitiesNavbarProps> = ({ isOpen, toggleActivities }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [isNotiOpen, setIsNotiOpen] = useState(false);

  const { is_subscribed, is_near_expiry, days_to_finish } = useSubscription();
  const { activities } = useActivities();
  const triggerToast = useNotification(); // Hook for triggering toasts

  // Subscription color logic based on subscription status and expiry
  const subscriptionColor = is_subscribed
    ? is_near_expiry
      ? "bg-yellow-500" // Yellow for near expiry
      : "bg-green-500" // Green for active subscriptions
    : "bg-red-500"; // Red for inactive subscription

  // Effect to show a toast when the subscription is near expiry
  useEffect(() => {
    if (is_near_expiry) {
      triggerToast(`الاشتراك سيُنهى خلال ${days_to_finish} يوم. يُرجى تجديد الاشتراك قريبًا.`, "warning");
    }
  }, [is_near_expiry, days_to_finish]);

  const filteredActivities = activities.filter((activity) => {
    const matchesSearchQuery =
      activity.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.activity_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.child_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.description.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesFilterType = filterType === "" || activity.activity_type === filterType;
    const matchesFilterStatus =
      filterStatus === "all" ||
      (filterStatus === "seen" && activity.is_seen) ||
      (filterStatus === "unseen" && !activity.is_seen);

    return matchesSearchQuery && matchesFilterType && matchesFilterStatus;
  });

  return (
    <nav className={`p-4 rounded-lg shadow-md w-full md:w-[70vw] mx-auto ${subscriptionColor}`}>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          {/* <label className="mr-2 text-white font-semibold">Toggle Activities</label> */}
          {/* <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isOpen}
              onChange={() => {
                toggleActivities();
                triggerToast(isOpen ? "Notifications disabled!" : "Notifications enabled!", "success");
              }}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:ring-2 peer-focus:ring-primary rounded-full peer dark:bg-gray-700 peer-checked:bg-primary transition-all"></div>
          </label> */}
        </div>

        <div className="relative">
          <button
            type="button"
            className="relative rounded-full bg-accent p-2 text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-primary"
            onClick={() => setIsNotiOpen(!isNotiOpen)}
          >
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
            </svg>
            {is_subscribed && (
              <span className="absolute -top-1 -right-1 bg-red-600 text-white text-xs rounded-full px-2 py-0.5">
                {days_to_finish}d
              </span>
            )}
          </button>
        </div>
      </div>

      
      {/* Notifications dropdown */}
      {isNotiOpen && (
        <div className="absolute right-10 mt-2 w-80 bg-white rounded-lg shadow-xl ring-1 ring-black/10 p-4 z-10">
          <input
            type="text"
            placeholder="Search activities..."
            className="w-full rounded-md border px-3 py-2 focus:border-primary focus:ring-primary-200"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="w-full mt-2 rounded-md border px-3 py-2 focus:border-primary focus:ring-primary-200"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="">All Categories</option>
            <option value="file_upload">File Upload</option>
            <option value="text">Message</option>
          </select>
          <select
            className="w-full mt-2 rounded-md border px-3 py-2 focus:border-primary focus:ring-primary-200"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="seen">Seen</option>
            <option value="unseen">Unseen</option>
          </select>
          <ul className="max-h-60 overflow-y-auto divide-y divide-gray-200 mt-3">
            {filteredActivities.length > 0 ? (
              filteredActivities.map((activity) => (
                <li key={activity.id} className="py-3">
                  <div className="font-semibold text-gray-800">{activity.title}</div>
                  <div className="font-semibold text-gray-600 bg-red-200 px-2 py-1 rounded w-fit">
                    ({activity.child_name})
                  </div>
                  <div className="text-sm text-gray-500">{activity.activity_type}</div>
                  <div
                    className={`text-sm border-2 ${activity.is_seen ? "border-green-500" : "border-red-500"} rounded p-1`}
                  >
                    {activity.description}
                  </div>
                </li>
              ))
            ) : (
              <li className="py-3 text-center text-gray-500">No activities found</li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default ActivitiesNavbar;
