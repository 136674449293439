import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { AuthProvider } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import { CourseProvider } from "./context/CourseContext";
import { EssayProvider } from "./context/EssayContext";
import { ActivitiesProvider } from "./context/ActivitiesContext";
import { AskuserProvider } from "./context/AskInstructorContext";
import { initializeNotifications } from "./initializeNotifications";
import { LocalStorageProvider, useLocalStorage } from './context/LocalStorageContext';
import useNotification from "./context/useNotification";
import { SubscriptionProvider } from "./context/SubscriptionContext";

// Create a separate component for initialization that can use hooks
const AppContent: React.FC = () => {
  const { value: user } = useLocalStorage<{
    id: string;
    token: { access: string };
  }>();
  const triggerToast = useNotification();

  useEffect(() => {
    initializeNotifications(user, triggerToast);
  }, [user]);

  return <AppRouter />; // Only render the AppRouter here
};

const App: React.FC = () => {
  return (
    <LocalStorageProvider storageKey="user" initialValue={null}>
      <ToastProvider>
        <CourseProvider>
          <AuthProvider>
            <EssayProvider>
              <ActivitiesProvider>
                <AskuserProvider>
                  <SubscriptionProvider>
                    <AppContent />
                  </SubscriptionProvider>
                </AskuserProvider>
              </ActivitiesProvider>
            </EssayProvider>
          </AuthProvider>
        </CourseProvider>
      </ToastProvider>
    </LocalStorageProvider>
  );
};

export default App;