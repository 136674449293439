import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../context/CourseContext";
import SessionDetails from "./SessionDetails";
import CourseList from "./CourseList";
import TaskList from "./TaskList"; // Import TaskList component

const CourseSessionPreview: React.FC = () => {
  const courseContext = useContext(CourseContext);
  const [showTasks, setShowTasks] = useState(false); // State to toggle task list visibility

  useEffect(() => {
    if (courseContext) {
      const { courses, activeCourse, handleSelectCourse } = courseContext;
      if (courses.length > 0 && !activeCourse) {
        handleSelectCourse(courses[0]); // Automatically select the first course if none is selected
      }
    }
  }, [courseContext]);

  if (!courseContext) {
    return (
      <div role="alert">
        Error: CourseContext is undefined. Please ensure the provider is
        wrapping this component.
      </div>
    );
  }

  const {
    courses,
    activeCourse,
    activeSession,
    activeSessionChildren,
    activeSessionChildrenAttendance,
    activeSessionChildrenAbsent,
    activeSessionChildrenTasks, // Get tasks from context
    problems,
    fetchProblems,
    postProblem,
    handleToggleSession,
    handleAddChildAction,
    handleSelectCourse,
    handleSelectSession,
    handleCreateTask,
    fetchCourses, // Assuming you have a refreshCourses function in context
  } = courseContext;

  const handleToggleTaskList = () => setShowTasks((prev) => !prev); // Toggle task list visibility

  // Refresh function to trigger the refreshCourses action
  const handleRefreshCourses = () => {
      fetchCourses(); // Call the refresh function passed from context
  };

  return (
    <div className="course-session-preview">
      {/* Add refresh button */}
      <div className="mb-4">
        {/* <button
          onClick={handleRefreshCourses}
          className="py-2 px-4 rounded-full bg-green-500 text-white font-medium transition-all duration-300 hover:bg-green-600"
        >
          Refresh Courses
        </button> */}
      </div>

      <CourseList
        courses={courses}
        activeCourse={activeCourse}
        activeSession={activeSession}
        setActiveCourse={handleSelectCourse}
        setActiveSession={handleSelectSession}
        refreshCourses={handleRefreshCourses} // Pass refreshCourses to CourseList
      />

      {/* Show session details if a session is selected */}
      {activeSession && activeCourse && (
        <SessionDetails
          course={activeCourse}
          session={activeSession}
          postProblem={postProblem}
          activeSessionChildrenAttendance={activeSessionChildrenAttendance}
          activeSessionChildrenAbsent={activeSessionChildrenAbsent}
          activeSessionChildren={activeSessionChildren}
          handleToggleSession={handleToggleSession}
          handleAddChildAction={handleAddChildAction}
          handleAddTaskToSession={handleCreateTask}
        />
      )}

      {/* Button to toggle task list visibility */}
      {activeSession && (
        <div className="mt-8 mb-12 flex flex-col">
          <button
            onClick={handleToggleTaskList}
            className="py-2 px-48 mb-4 rounded-full bg-error  text-white font-medium transition-all duration-500 hover:bg-primary max-sm:w-fit max-sm:px-32 max-sm:mx-auto "
            aria-expanded={showTasks}
          >
            {showTasks ? "Hide Tasks" : "Show Tasks"}
          </button>

          {/* Conditionally render TaskList */}
          {showTasks && <TaskList tasks={activeSessionChildrenTasks} />}
        </div>
      )}
    </div>
  );
};

export default CourseSessionPreview;
