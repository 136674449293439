// src/context/LocalStorageContext.tsx
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
  } from 'react';
  
  interface LocalStorageContextProps<T> {
    value: T | null;
    setValue: (newValue: T | null) => void;
  }
  
  const LocalStorageContext = createContext<LocalStorageContextProps<any>>({
    value: null,
    setValue: () => {},
  });
  
  interface LocalStorageProviderProps<T> {
    children: ReactNode;
    storageKey: string;
    initialValue: T | null;
  }
  
  export const LocalStorageProvider = <T extends unknown>({
    children,
    storageKey,
    initialValue,
  }: LocalStorageProviderProps<T>) => {
    const [value, setValue] = useState<T | null>(() => {
      const storedValue = localStorage.getItem(storageKey);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    });
  
    useEffect(() => {
      if (value === null) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, JSON.stringify(value));
      }
    }, [value, storageKey]);
  
    return (
      <LocalStorageContext.Provider value={{ value, setValue }}>
        {children}
      </LocalStorageContext.Provider>
    );
  };
  
  export const useLocalStorage = <T extends unknown>() => {
    const context = useContext(LocalStorageContext) as LocalStorageContextProps<T>;
    if (!context) {
      throw new Error(
        'useLocalStorage must be used within a LocalStorageProvider'
      );
    }
    return context;
  };